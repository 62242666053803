export const tagsList = [
  {
    name: 'Deep Learning',
    code: 'DL',
  },
  {
    name: 'Data Analytics',
    code: 'DA',
  },
  {
    name: 'Machine Learning',
    code: 'ML',
  },
  {
    name: 'Software Development',
    code: 'SD',
  },
];
