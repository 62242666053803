import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';
import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { blogMenuLinks } from '../components/_config/menu-links';
import { StyledH1 } from '../components/_shared/styled-headings';
import { StyledFullHeightSection } from '../components/_shared/styled-section';
import { flexCenter } from '../components/_shared/styled-mixins';
import { StyledSeparator } from '../components/_shared/styled-separator';
import ProjectCard from '../components/project-card';
import { tagsList } from '../components/_config/tags';
import { projectTransition } from '../animations';

const StyledPaginationContainer = styled.div`
  ${flexCenter};
  width: 100%;
  margin-top: 2.5rem;

  & ul.ant-pagination {
    cursor: pointer;
    display: flex;
    list-style: none;

    & > li {
      ${flexCenter};
      min-width: 1rem;
    }
    & > li.ant-pagination-item {
      font-size: 1.5rem;
      padding: 0 0.25rem;
    }
    & > li.ant-pagination-disabled > a {
      color: var(--disabled-color);
    }
    & > li.ant-pagination-item-active > a {
      text-decoration: underline;
    }
  }
`;

const StyledTopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;

const StyledProjectsH1 = styled(StyledH1)`
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  & > * + * {
    margin-left: 20px;
  }
`;

const StyledFilterButton = styled.button`
  padding: 10px 20px;
  border-radius: 10px;
  color: white;
  background-color: transparent;
  border: 1px solid white;
  cursor: pointer;
  font-weight: 700;
  transition: all 0.2s ease-out;
  &:hover {
    background-color: #f1b631;
    transform: translateY(-3px);
    color: black;
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.75);
  }
  &:active {
    background-color: #805b08;
  }
  &.open {
    background-color: #f1b631;
    color: black;
    transform: translateY(-3px);
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.75);
  }
`;

const Projects = ({ data }) => {
  const [projects, setProjects] = React.useState(data.allStrapiProjects.nodes);
  let [currentPage, setCurrentPage] = React.useState(1);
  const [selectedFilter, setSelectedFilter] = React.useState('ALL');

  const projectContainerRef = React.useRef(null);

  React.useLayoutEffect(() => {
    projectTransition(projectContainerRef);
  }, [projects]);

  const onPaginationChange = (page) => {
    setCurrentPage(page);
  };

  const handleApply = (tech) => {
    setSelectedFilter(tech.code);
    if (tech === 'ALL') {
      setSelectedFilter('ALL');
      setProjects(data.allStrapiProjects.nodes);
      return;
    }
    const newProjects = data.allStrapiProjects.nodes.filter((project) => project.Tags.includes(tech.code));
    setProjects(newProjects);
  };

  let paginationSize = data.site.siteMetadata.paginationPageSize;
  let leftCursor = (currentPage - 1) * paginationSize;
  let rightCursor = leftCursor + paginationSize;

  return (
    <Layout menuLinks={blogMenuLinks}>
      <SEO title="Projects" />
      <StyledFullHeightSection>
        <StyledTopSection>
          <StyledProjectsH1>Projects</StyledProjectsH1>
        </StyledTopSection>
        <StyledButtonGroup>
          <StyledFilterButton className={selectedFilter === 'ALL' ? 'open' : ''} onClick={() => handleApply('ALL')}>
            Show All
          </StyledFilterButton>
          {tagsList.map((tech, index) => (
            <StyledFilterButton
              className={selectedFilter === tech.code ? 'open' : ''}
              key={index}
              onClick={() => handleApply(tech)}
            >
              {tech.name}
            </StyledFilterButton>
          ))}
        </StyledButtonGroup>
        <StyledSeparator />
        <div ref={projectContainerRef} style={{width: '100%'}}>
          {projects.slice(leftCursor, rightCursor).map((node) => {
            console.log(node);
            return (
              <ProjectCard
                key={node.Name}
                title={node.Name}
                demoLink={node.demo_link}
                repoLink={node.Repo}
                kaggleLink={node.Kaggle}
                techs={node.Technologies}
                html={node.Desc}
              />
            );
          })}
        </div>
        <StyledPaginationContainer>
          <Pagination
            pageSize={paginationSize}
            current={currentPage}
            onChange={onPaginationChange}
            total={projects.length}
          />
        </StyledPaginationContainer>
      </StyledFullHeightSection>
    </Layout>
  );
};

Projects.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Projects;

export const pageQuery = graphql`
  query {
    allStrapiProjects {
      nodes {
        Desc
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        Name
        Repo
        Kaggle
        Tags
        Technologies
      }
    }
    site {
      siteMetadata {
        paginationPageSize
      }
    }
  }
`;
